import type { Ref } from 'vue'

import type { PublicImage } from '@/service/__generated-api'
import { getThumb } from '@/utils/images/imgproxy'

type GeneralPageSEOData = {
  site_title?: string
  meta_title?: string
  meta_description?: string
  meta_keywords?: string
  og_title?: string
  og_description?: string
  og_image?: string | PublicImage
  favicon?: PublicImage
  favicon_ico?: string
}

export default function usePrepareSEOHead(
  page: Ref<GeneralPageSEOData | null>
) {
  const meta = []
  const link = []

  if (page.value?.site_title || page.value?.meta_title) {
    meta.push({
      key: 'title',
      name: 'title',
      content: page.value?.site_title || page.value?.meta_title
    })
  }

  if (page.value?.meta_description) {
    meta.push({
      key: 'description',
      name: 'description',
      content: page.value.meta_description
    })
  }

  if (page.value?.meta_keywords) {
    meta.push({
      key: 'keywords',
      name: 'keywords',
      content: page.value.meta_keywords
    })
  }

  if (page.value?.og_title) {
    meta.push({
      key: 'og:title',
      property: 'og:title',
      content: page.value.og_title
    })
  }

  if (page.value?.og_description) {
    meta.push({
      key: 'og:description',
      property: 'og:description',
      content: page.value.og_description
    })
  }

  if (page.value?.og_image) {
    meta.push({
      key: 'og:image',
      property: 'og:image',
      content:
        typeof page.value.og_image === 'string'
          ? page.value.og_image
          : page.value.og_image?.file
    })
  }

  if (page.value?.favicon_ico) {
    link.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: page.value.favicon_ico || '/favicon.ico'
    })
  }

  if (page.value?.favicon) {
    link.push(
      ...[
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: getThumb(page.value.favicon, 180, 180, 'fit', 'png')
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: getThumb(page.value.favicon, 32, 32, 'fit', 'png')
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: getThumb(page.value.favicon, 16, 16, 'fit', 'png')
        }
      ]
    )

    if (page.value.favicon.is_svg) {
      link.push({
        rel: 'icon',
        type: 'image/svg+xml',
        href: getThumb(page.value.favicon, 48, 48, 'fit', 'svg')
      })
    }
  }

  return { meta, link }
}
